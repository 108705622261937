<template>
  <div>
    <b-modal
      id="modal-input-csv"
      size="lg"
      centered
      title="Input Data Absensi Karyawan (CSV)"
      header-bg-variant="success"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Upload File csv" label-cols-md="3">
          <b-form-file type="file"></b-form-file>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-csv')">
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "modalInput",
  data() {
    return {};
  },
};
</script>
