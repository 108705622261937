<template>
  <div>
    <b-modal
      id="modal-telat"
      centered
      title="Update Status Absensi Karyawan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form v-if="dataEdit">
        <b-form-group label="Karyawan" label-cols-md="3">
          <b-form-input v-model="data.namaKaryawan" disabled />
        </b-form-group>

        <b-form-group label="Tanggal" label-cols-md="3">
          <date-picker
            :placeholder="Absenadates(dataEdit)"
            disabled
            style="width: 100%"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Status Absen" label-cols-md="3">
          <b-form-select
            v-model="dataEdit.statusIjin"
            :options="status2"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Absen Masuk" label-cols-md="3">
          <b-form-select
            v-model="dataEdit.masukTelat"
            :options="status"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Absen Istirahat" label-cols-md="3">
          <b-form-select
            v-model="dataEdit.istirahatTelat"
            :options="status"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Absen Pulang" label-cols-md="3">
          <b-form-select
            v-model="dataEdit.keluarTelat"
            :options="status"
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Keterangan" label-cols-md="3">
          <b-form-textarea v-model="data.keterangan" />
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-telat')">
          Batal
        </b-button>
        <b-button variant="primary" @click="simpan()">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  components: { Multiselect, DatePicker },

  name: "modalInput",
  props: ["data", "dataEdit"],
  data() {
    return {
      moment,
      button: "Simpan",
      busy: false,
      listKaryawan: [],
      shift: [],
      status: [
        { value: 0, text: "Tepat Waktu" },
        { value: 1, text: "Terlambat" },
        // { value: 2, text: "Ijin Terlambat" },
      ],
      status2: [
        { value: 0, text: "Berangkat" },
        { value: 1, text: "Cuti" },
        { value: 2, text: "Dinas" },
        { value: 3, text: "Cuti Hamil" },
        { value: 4, text: "Ijin" },
        { value: 5, text: "Sakit" },
        { value: 6, text: "Alfa" },
      ],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mounted() {
    this.getKaryawan();
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    Absenadates(x) {
      if (x) {
        return moment(this.dataEdit.tanggalAbsen).format("LL");
      } else {
        return "";
      }
    },
    async getKaryawan() {
      let vm = this;
      // let listKaryawan = await axios.get(
      //   ipBackend + "suratPerjanjianKerja/listPerjanjianDibuat"
      // );

      // console.log(listKaryawan.data.data, "ini list karyawan");
      // let penampung = listKaryawan.data.data;
      // vm.listKaryawan = penampung.map((item) => {
      //   item.dataKaryawan =
      //     item.namaKaryawan + " - " + item.namaDivisi + " - " + item.namaPosisi;
      //   return item;
      // });

      let shift = await axios.get(ipBackend + "masterShift/list");
      // console.log(shift.data.data,'shift');
      vm.shift = shift.data.data.map((item) => {
        return { value: item.id, text: item.kategoriShift };
      });
    },

    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      axios
        .post(
          ipBackend + "absensiKaryawanMobile/updateStatusTerlambat",
          vm.dataEdit
        )
        .then((res) => {
          console.log(res.data.message);
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL INPUT DATA ABSEN KARYAWAN",
              showing: true,
            });
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
            vm.$bvModal.hide("modal-input");
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
          // vm.$bvModal.hide("modal-input");
        });
    },

    // simpan() {
    //   let vm = this;
    //   vm.button = "Mohon Tunggu";
    //   vm.busy = true;
    //   let bulkAbsenKaryawan = [];
    //   let penampung = {};
    //   let penampung1 = {};
    //   let tgl = moment(vm.data.tglAbsenKaryawan).format("YYYY/MM/DD");
    //   vm.data.dataKaryawanId = vm.data.dataKaryawanId.dataKaryawanId;
    //   vm.data.masterIjinId = vm.data.keteranganIjin.id;
    //   if (vm.data.statusIjin == 0) {
    //     penampung.tglAbsenKaryawan = `${tgl} ${vm.data.jamMasuk}`;
    //     penampung.statusIjin = 0;
    //     penampung1.tglAbsenKaryawan = `${tgl} ${vm.data.jamKeluar}`;
    //     penampung1.statusIjin = 0;
    //     bulkAbsenKaryawan.push(penampung);
    //     bulkAbsenKaryawan.push(penampung1);
    //   } else if (vm.data.statusIjin == 1) {
    //     penampung.tglAbsenKaryawan = tgl;
    //     penampung.statusIjin = vm.data.statusIjin;
    //     penampung.masterIjinId = vm.data.masterIjinId;
    //     penampung1.tglAbsenKaryawan = tgl;
    //     penampung1.statusIjin = vm.data.statusIjin;
    //     penampung1.masterIjinId = vm.data.masterIjinId;
    //     bulkAbsenKaryawan.push(penampung);
    //     bulkAbsenKaryawan.push(penampung1);
    //   } else {
    //     penampung.tglAbsenKaryawan = tgl;
    //     penampung.statusIjin = vm.data.statusIjin;
    //     penampung1.tglAbsenKaryawan = tgl;
    //     penampung1.statusIjin = vm.data.statusIjin;
    //     bulkAbsenKaryawan.push(penampung);
    //     bulkAbsenKaryawan.push(penampung1);
    //   }
    //   axios
    //     .post(ipBackend + "absensiKaryawan/register", {
    //       dataKaryawanId: vm.data.dataKaryawanId,
    //       bulkAbsenKaryawan: bulkAbsenKaryawan,
    //     })
    //     .then((res) => {
    //       if (res.data.message == "sukses") {
    //         vm.button = "Simpan";
    //         vm.busy = false;
    //         vm.$emit("alertFromChild", {
    //           variant: "success",
    //           msg: "BERHASIL INPUT DATA ABSEN KARYAWAN",
    //           showing: true,
    //         });
    //         vm.data.dataKaryawanId = "";
    //         vm.data.tglAbsenKaryawan = "";
    //         vm.data.jamMasuk = "";
    //         vm.data.jamKeluar = "";
    //         vm.$bvModal.hide("modal-input");
    //       } else {
    //         vm.button = "Simpan";
    //         vm.busy = false;

    //         vm.$emit("alertFromChild", {
    //           variant: "danger",
    //           msg: _.toUpper(res.data.message),
    //           showing: true,
    //         });
    //         vm.$bvModal.hide("modal-input");
    //       }
    //     })
    //     .catch((err) => {
    //       vm.button = "Simpan";
    //       vm.busy = false;
    //       vm.$emit("alertFromChild", {
    //         variant: "danger",
    //         msg: "TERJADI KESALAHAN PADA SERVER",
    //         showing: true,
    //       });
    //       vm.$bvModal.hide("modal-input");
    //     });
    // },
  },
};
</script>
