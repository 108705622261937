a
<template>
  <b-modal
    id="modal-detail"
    size="xl"
    centered
    scrollable
    :title="title"
    header-bg-variant="primary"
    header-text-variant="light"
  >
    <div>
      <!-- <b-row>
        <b-col cols="12" md="12" lg="12">
          <h4><strong>Data Absensi Kedatangan</strong></h4>
          <hr />
        </b-col>
      </b-row> -->

      <b-row class="mt-3">
        <b-col md="3">
          <b-form-group
            :label="$store.state.table.perHalaman"
            label-for="per-page-select"
            label-cols-md="6"
            label-align-md="left"
            label-size="md"
            style="background-color: "
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="md"
            ></b-form-select>
          </b-form-group>
        </b-col>

        <b-col md="6" offset-md="3">
          <b-form-group
            :label="$store.state.table.cari"
            label-for="filter-input"
            label-cols-md="3"
            label-align-md="right"
            label-size="md"
          >
            <b-input-group size="md">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                :placeholder="$store.state.table.cariHolder"
              ></b-form-input>

              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                  variant="danger"
                  >{{ $store.state.table.hapusCari }}</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table
            :items="items"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            stacked="md"
            responsive
            show-empty
            small
            @filtered="onFiltered"
            bordered
            striped
            hover
            :busy="tableBusy"
          >
            <template #cell(no)="item">
              {{ item.index + 1 }}
            </template>
            <template #cell(statusIjin)="item">
              <p v-if="item.item.statusIjin == 0">Berangkat</p>
              <p v-if="item.item.statusIjin == 1">CUTI</p>
              <p v-if="item.item.statusIjin == 2">DINAS</p>
              <p v-if="item.item.statusIjin == 3">CUTI HAMIL</p>
              <p v-if="item.item.statusIjin == 4">IJIN</p>
              <p v-if="item.item.statusIjin == 5">SAKIT</p>
              <p v-if="item.item.statusIjin == 6">ALPHA / MANGKIR</p>
            </template>
            <template #cell(tanggalAbsen)="item">
              <div>{{ moment(item.item.tanggalAbsen).format("ll") }}</div>
            </template>
            <template #cell(actions)="item">
              <b-button
                v-b-modal.modal-foto
                class="mr-1"
                v-c-tooltip.hover.click="'Foto Data'"
                @click="
                  (src1 = ipBackend + 'asset/file/' + item.item.fotoAbsensi),
                    (src2 =
                      ipBackend + 'asset/file/' + item.item.fotoAbsensiKeluar)
                "
                ><CIcon name="cil-file"
              /></b-button>
              <b-button
                variant="warning"
                class="mr-1"
                v-c-tooltip.hover.click="'Edit Data'"
                v-b-modal.modal-telat
                @click="dataEdit = item.item"
                ><CIcon name="cil-pencil" />{{ item.actions }}</b-button
              >
              {{ item.actions }}
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="5" offset-md="7">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
          ></b-pagination>
        </b-col>
      </b-row>

      <div>
        <b-modal
          id="modal-foto"
          size="lg"
          centered
          scrollable
          title="Foto Absensi Karyawan"
          header-bg-variant="success"
          header-text-variant="light"
        >
          <div class="d-flex">
            <div>
              <div class="row">
                <div class="col text-center">
                  <span><h5>Foto Masuk</h5></span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <img
                    :src="src1"
                    alt="foto absen masuk"
                    style="width: 300px; height: 300px"
                  />
                </div>
              </div>
            </div>
            <div>
              <div class="row">
                <div class="col text-center">
                  <span><h5>Foto Pulang</h5></span>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <img
                    :src="src2"
                    alt="foto absen pulang"
                    style="width: 300px; height: 300px"
                  />
                </div>
              </div>
            </div>
          </div>
          <template #modal-footer>
            <b-button variant="secondary" @click="$bvModal.hide('modal-foto')">
              Batal
            </b-button>
          </template>
        </b-modal>
      </div>
    </div>
    <ModalTelat :dataEdit="dataEdit" :data="data" />

    <template #modal-footer>
      <b-button variant="danger" @click="$bvModal.hide('modal-detail')">
        Batal
      </b-button>
      <b-button variant="success" @click="$bvModal.show('modal-confirm')">
        Print Absensi
      </b-button>
    </template>

    <div>
      <b-modal
        id="modal-confirm"
        centered
        title="Konfirmasi!"
        header-bg-variant="success"
        header-text-variant="light"
      >
        <div class="d-flex">
          <div>
            <div class="row">
              <div class="col text-center">
                <span
                  ><h5>Download Absensi - {{ data.namaKaryawan }}</h5></span
                >
              </div>
            </div>
          </div>
        </div>
        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-confirm')">
            Batal
          </b-button>
          <b-button variant="success" @click="printAbsensi()"> Print </b-button>
        </template>
      </b-modal>
    </div>
  </b-modal>
</template>
<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import "moment/locale/id";
import ModalTelat from "./modalUpdate.vue";
export default {
  name: "tabAbsensiKedatangan",
  props: ["data", "bulan", "tahun", "detail"],
  components: {
    ModalTelat,
  },
  data() {
    return {
      ipBackend,
      title: null,
      moment,
      dataAbsen: {},
      src1: "",
      src2: "",
      dataEdit: null,
      items: [
        {
          no: "-",
          tanggal: "-",
          jamMasuk: "-",
          jamKeluar: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "tanggalAbsen",
          label: "Tanggal",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusIjin",
          label: "Status Absensi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "masuk",
          label: "Jam Masuk Kerja",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "break",
          label: "Jam Mulai Istirahat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "afterBreak",
          label: "Jam Selesai Istirahat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "keluar",
          label: "Jam Pulang",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 31,
      pageOptions: [10, 25, 30, 31, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // this.dataAbsen.periode = this.bulan;
    // this.dataAbsen.tahun = this.tahun;
    // this.setPeriode();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async setData() {
      let vm = this;
      vm.dataAbsen.dataKaryawanId = vm.data.dataKaryawanId;
      vm.dataAbsen.periode = vm.bulan;
      vm.dataAbsen.tahun = vm.tahun;
      let karyawan = await axios.get(
        ipBackend + "dataKaryawan/detailsByDataKaryawanId/" + vm.dataAbsen.dataKaryawanId
      );
      // console.log(karyawan.data.data,'karyawan')

      console.log(karyawan.data.data[0], "tgl gaji");
      if (karyawan.data.data[0].tanggalGaji == 25) {
        vm.setPeriode25();
      } else {
        vm.setPeriode1();
      }
    },
    setPeriode25() {
      let vm = this;
      let p = vm.dataAbsen.periode;
      let t = vm.dataAbsen.tahun;
      if (p && t) {
        let q = moment(`${t}${p}25`).format();
        let r = moment(q).subtract(1, "M").add(1, "days").format();
        vm.dataAbsen.tglAkhir = q;
        vm.dataAbsen.tglAwal = r;
        vm.getData();
      }
    },
    setPeriode1() {
      let vm = this;
      let p = vm.dataAbsen.periode;
      let t = vm.dataAbsen.tahun;
      if (p && t) {
        let q = moment(`${t}${p}01`).format();
        let r = moment(q).add(1, "M").add(1, "days").format();
        vm.dataAbsen.tglAkhir = r;
        vm.dataAbsen.tglAwal = q;
        vm.getData();
      }
    },
    async getData() {
      let vm = this;
      vm.items = []
      vm.dataAbsen.dataKaryawanId = vm.data.dataKaryawanId;
      let absensi = await axios.post(
        ipBackend + "absensiKaryawanMobile/listKedatanganByDataKaryawanId/",
        vm.dataAbsen
      );
      // console.log(absensi.data, "aa");
      vm.items = absensi.data.data;
    },
    async printAbsensi() {
      let vm = this;
      let x = `?dataKaryawanId=${vm.dataAbsen.dataKaryawanId}&tglAwal=${moment(
        vm.dataAbsen.tglAwal
      ).format("YYYY-MM-DD")}&tglAkhir=${moment(vm.dataAbsen.tglAkhir).format(
        "YYYY-MM-DD"
      )}&namaKaryawan=${vm.data.namaKaryawan}`;
        console.log(x);
      window.open(ipBackend + "dataKaryawan/CetakAbsensi" + x, "_blank");
    },
  },
  watch: {
    detail: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.title = "Detail Absensi Karyawan " + this.data.namaKaryawan;
        this.setData();
      }
    },
  },
};
</script>
