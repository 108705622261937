<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Pengajuan Cuti </strong>
              </h5>
            </template>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button variant="primary" v-b-modal.modal-input class="mr-3"
                  ><CIcon name="cil-plus" />
                  {{ $store.state.table.tambahModal }}</b-button
                >

                <!-- <b-button variant="success" v-b-modal.modal-input-csv
                  ><CIcon name="cil-plus" /> Upload File CSV</b-button
                > -->
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-alert dismissible fade :show="showing" :variant="variant">{{
                  msg
                }}</b-alert>
              </b-col>
            </b-row>

            <!-- <b-row>
              <b-col md="6">
                <b-form-group
                  label="Pilih Bulan"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    v-model="bulan"
                    :options="listBulan"
                    @change="getData()"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Pilih Tahun"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    v-model="tahun"
                    :options="listTahun"
                    @change="getData()"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row> -->

            <b-row>
              <b-col md="6" offset-md="6">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template>
                  <template #cell(tanggalPengajuanCuti)="item">
                    {{ moment(item.item.tanggalPengajuanCuti).format("ll") }}
                  </template>
                  <template #cell(tanggalCuti)="item">
                    {{ moment(item.item.tanggalCuti).format("ll") }}
                  </template>

                  <template #cell(statusPengajuan)="item">
                    <p v-if="item.item.statusPengajuan == 0">
                      Menunggu Approval
                    </p>
                    <p v-if="item.item.statusPengajuan == 1">Disetujui</p>
                    <p v-if="item.item.statusPengajuan == 2">Ditolak</p>
                  </template>
                  <template #cell(actions)="item">
                    <b-button
                      v-if="item.item.statusPengajuan == 0"
                      variant="success"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Approve Cuti'"
                      v-b-modal.modal-approve
                      @click="data = item.item"
                      ><CIcon name="cil-check" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      v-if="item.item.statusPengajuan == 0"
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'reject Cuti'"
                      v-b-modal.modal-reject
                      @click="data = item.item"
                      ><CIcon name="cil-x" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="5" offset-md="4">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-detail :data="data" :detail="detail" />
    <modal-input
      :listKaryawan="listKaryawan"
      @alertFromChild="triggerAlert($event)"
    />
    <modal-input-csv />
    <!--<modal-edit />
    <modal-delete /> -->
    <div>
      <b-modal
        id="modal-approve"
        size="lg"
        centered
        scrollable
        title="Setujui Cuti"
        header-bg-variant="info"
        header-text-variant="light"
      >
        <h4>
          Apakah anda yakin akan
          <span class="text-success">Menyetujui Pengajuan Cuti</span> a.n
          <span class="text-success">{{ data.namaKaryawan }}</span>
        </h4>

        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-approve')">
            Batal
          </b-button>
          <b-button variant="primary" @click="approve()" :disabled="busy">
            Yakin
          </b-button>
        </template></b-modal
      >
    </div>
    <div>
      <b-modal
        id="modal-reject"
        size="lg"
        centered
        scrollable
        title="Tolak Cuti"
        header-bg-variant="info"
        header-text-variant="light"
      >
        <h4>
          Apakah anda yakin akan
          <span class="text-danger">Menolak Pengajuan Cuti</span> a.n
          <span class="text-danger">{{ data.namaKaryawan }}</span>
        </h4>

        <template #modal-footer>
          <b-button variant="secondary" @click="$bvModal.hide('modal-reject')">
            Batal
          </b-button>
          <b-button variant="primary" @click="reject()" :disabled="busy">
            Yakin
          </b-button>
        </template></b-modal
      >
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import modalDetail from "../../../component/hcms/rekapAbsensi/modalDetail.vue";
import modalInput from "./modalAddCuti.vue";
import modalInputCsv from "../../../component/hcms/rekapAbsensi/modalInputCsv.vue";
import moment from "moment";
import "moment/locale/id";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  components: {
    Multiselect,
    modalInput,
    modalInputCsv,
    modalDetail,
  },
  name: "rekapAbsensi",
  data() {
    return {
      moment,
      data: "",
      busy: false,
      listKaryawan: [],
      detail: false,
      items: [
        {
          no: "-",
          nama: "-",
          posisi: "-",
          masuk: "-",
          alfa: "-",
          cutiIjin: "-",
          sakit: "-",
        },
      ],
      msg: "",
      variant: "",
      showing: false,
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "namaKaryawan",
          label: "Karyawan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "NIK",
          label: "NIK",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggalPengajuanCuti",
          label: "Tanggal Pengajuan",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "tanggalCuti",
          label: "Tanggal Cuti",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "totalCuti",
          label: "Lama Cuti (hari)",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "statusPengajuan",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-center",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["namaKaryawan"],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getNow() {
      let vm = this;
      vm.bulan = moment().format("MM");
      vm.tahun = moment().format("YYYY");
      this.getData();
    },
    async getData() {
      let vm = this;
      vm.tableBusy = true;
      let listAbsen = await axios.get(ipBackend + "pengajuanCuti/list");
      // console.log(listAbsen.data.data);
      vm.items = listAbsen.data.data;
      vm.totalRows = vm.items.length;

      let listKaryawan = await axios.get(
        ipBackend + "pengajuanCuti/cekCutiKaryawan"
      );
      this.listKaryawan = listKaryawan.data.data.map((item) => {
        item.dataKaryawan =
          item.namaKaryawan + " - " + item.NIK;
        return item;
      });
      console.log(vm.listKaryawan, "karyawanaa");
      vm.tableBusy = false;
    },
    async getKeterangan() {
      let listKeterangan = await axios.get(ipBackend + "masterCuti/list");
      this.listKeterangan = listKeterangan.data.data;
    },
    approve() {
      let vm = this;
      vm.data.id = vm.data.pengajuanCutiId;
      vm.data.statusPengajuan = 1;
      axios
        .post(ipBackend + "pengajuanCuti/update", vm.data)
        .then((res) => {
          if (res.data.status == 200) {
            if (res.data.message == "sukses") {
              vm.$bvModal.hide("modal-approve");
              vm.showing = true;
              vm.variant = "success";
              vm.msg = "Berhasil Menyetujui Cuti";
              setTimeout(() => {
                vm.showing = false;
              }, 4000);
              vm.getData();
            } else {
              vm.$bvModal.hide("modal-approve");
              vm.showing = true;
              vm.variant = "danger";
              vm.msg = res.data.message;
              setTimeout(() => {
                vm.showing = false;
              }, 4000);
            }
          }
        })
        .catch((err) => {
          // console.log(err);
          vm.$bvModal.hide("modal-approve");
          vm.showing = true;
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
    reject() {
      let vm = this;
      vm.data.id = vm.data.pengajuanCutiId;
      vm.data.statusPengajuan = 2;
      axios
        .post(ipBackend + "pengajuanCuti/update", vm.data)
        .then((res) => {
          if (res.data.status == 200) {
            if (res.data.message == "sukses") {
              vm.$bvModal.hide("modal-reject");
              vm.showing = true;
              vm.variant = "success";
              vm.msg = "Berhasil Menolak Cuti";
              setTimeout(() => {
                vm.showing = false;
              }, 4000);
              vm.getData();
            } else {
              vm.$bvModal.hide("modal-reject");
              vm.showing = true;
              vm.variant = "danger";
              vm.msg = res.data.message;
              setTimeout(() => {
                vm.showing = false;
              }, 4000);
            }
          }
        })
        .catch((err) => {
          // console.log(err);
          vm.$bvModal.hide("modal-reject");
          vm.showing = true;
          vm.variant = "danger";
          vm.msg = "TERJADI KESALAHAN PADA SERVER";
          setTimeout(() => {
            vm.showing = false;
          }, 4000);
        });
    },
    triggerAlert(event) {
      let vm = this;
      // vm.showing = event.showing;
      // vm.variant = event.variant;
      // vm.msg = event.msg;
      vm.$store.commit("set_alert", event);
      vm.getData();
      // setTimeout(() => {
      //   vm.showing = false;
      // }, 4000);
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
