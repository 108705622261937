<template>
  <div>
    <b-modal
      id="modal-input"
      centered
      title="Input Pengajuan Cuti Karyawan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Karyawan" label-cols-md="3">
          <multiselect
            v-model="data.dataKaryawanId"
            :options="listKaryawan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="-- Pilih Karyawan --"
            label="dataKaryawan"
            track-by="NIK"
            @input="cekSisa(data.dataKaryawanId)"
          ></multiselect>
        </b-form-group>

        <b-form-group label="Sisa Cuti" label-cols-md="3">
          <b-form-input
            disabled
            v-model="data.sisaCuti"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Tanggal Cuti" label-cols-md="3">
          <date-picker
            v-model="data.tanggalCuti"
            style="width: 100%"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Lama Cuti (Hari)" label-cols-md="3">
          <b-form-input
            :state="checkIfValid('jumlahHari')"
            type="number"
            v-model="data.jumlahHari"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
        <b-button variant="primary" @click="simpan()" :disabled="!isValid">
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required, requiredIf, maxValue } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  components: { Multiselect, DatePicker },

  name: "modalInput",
  props: ["listKaryawan"],
  data() {
    return {
      data: {
        dataKaryawanId: "",
        tanggalPengajuanCuti: "",
        jumlahHari: "",
        tanggalCuti: "",
        sisaCuti: "",
        maxCuti: 2,
      },
      button: "Simpan",
      busy: false,
      // listKaryawan: [],
      shift: [],
      status: [
        { value: 0, text: "Berangkat" },
        { value: 1, text: "Cuti" },
        { value: 2, text: "Dinas" },
        { value: 3, text: "Cuti Hamil" },
        { value: 4, text: "Ijin" },
        { value: 5, text: "Sakit" },
        { value: 6, text: "Alfa" },
      ],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      dataKaryawanId: {
        required,
      },
      tanggalCuti: {
        required,
      },
      jumlahHari: {
        required,
        maxValue: maxValue(2),
      },
    },
  },
  mounted() {
    this.getKaryawan();
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async cekSisa(x) {
      let cuti = await axios.get(
        ipBackend + "pengajuanCuti/cekSisaCuti/" + x.id
      );
      this.data.sisaCuti = cuti.data.data[0].totalCuti;
    },
    async getKaryawan() {
      let vm = this;
      let listKaryawan = await axios.get(
        ipBackend + "suratPerjanjianKerja/listPerjanjianDibuat"
      );

      console.log(listKaryawan.data.data, "ini list karyawanaa");
      let penampung = listKaryawan.data.data;
      vm.listKaryawan = penampung.map((item) => {
        item.dataKaryawan =
          item.namaKaryawan + " - " + item.namaDivisi + " - " + item.namaPosisi;
        return item;
      });

      let shift = await axios.get(ipBackend + "masterShift/list");
      // console.log(shift.data.data);
      vm.shift = shift.data.data.map((item) => {
        return { value: item.id, text: item.kategoriShift };
      });
    },

    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.dataKaryawanId = vm.data.dataKaryawanId.id;
      vm.data.tanggalPengajuanCuti = moment();
      axios
        .post(ipBackend + "pengajuanCuti/register", vm.data)
        .then((res) => {
          // console.log(res.data.message);
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.data = {
              dataKaryawanId: "",
              tanggalPengajuanCuti: "",
              jumlahHari: "",
              tanggalCuti: "",
              sisaCuti: "",
              maxCuti: 2,
            };
            vm.$bvModal.hide("modal-input");
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL INPUT DATA CUTI KARYAWAN",
              showing: true,
            });
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$bvModal.hide("modal-input");
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
            vm.$bvModal.hide("modal-input");
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$bvModal.hide("modal-input");
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
          // vm.$bvModal.hide("modal-input");
        });
    },

    // simpan() {
    //   let vm = this;
    //   vm.button = "Mohon Tunggu";
    //   vm.busy = true;
    //   let bulkAbsenKaryawan = [];
    //   let penampung = {};
    //   let penampung1 = {};
    //   let tgl = moment(vm.data.tglAbsenKaryawan).format("YYYY/MM/DD");
    //   vm.data.dataKaryawanId = vm.data.dataKaryawanId.dataKaryawanId;
    //   vm.data.masterIjinId = vm.data.keteranganIjin.id;
    //   if (vm.data.statusIjin == 0) {
    //     penampung.tglAbsenKaryawan = `${tgl} ${vm.data.jamMasuk}`;
    //     penampung.statusIjin = 0;
    //     penampung1.tglAbsenKaryawan = `${tgl} ${vm.data.jamKeluar}`;
    //     penampung1.statusIjin = 0;
    //     bulkAbsenKaryawan.push(penampung);
    //     bulkAbsenKaryawan.push(penampung1);
    //   } else if (vm.data.statusIjin == 1) {
    //     penampung.tglAbsenKaryawan = tgl;
    //     penampung.statusIjin = vm.data.statusIjin;
    //     penampung.masterIjinId = vm.data.masterIjinId;
    //     penampung1.tglAbsenKaryawan = tgl;
    //     penampung1.statusIjin = vm.data.statusIjin;
    //     penampung1.masterIjinId = vm.data.masterIjinId;
    //     bulkAbsenKaryawan.push(penampung);
    //     bulkAbsenKaryawan.push(penampung1);
    //   } else {
    //     penampung.tglAbsenKaryawan = tgl;
    //     penampung.statusIjin = vm.data.statusIjin;
    //     penampung1.tglAbsenKaryawan = tgl;
    //     penampung1.statusIjin = vm.data.statusIjin;
    //     bulkAbsenKaryawan.push(penampung);
    //     bulkAbsenKaryawan.push(penampung1);
    //   }
    //   axios
    //     .post(ipBackend + "absensiKaryawan/register", {
    //       dataKaryawanId: vm.data.dataKaryawanId,
    //       bulkAbsenKaryawan: bulkAbsenKaryawan,
    //     })
    //     .then((res) => {
    //       if (res.data.message == "sukses") {
    //         vm.button = "Simpan";
    //         vm.busy = false;
    //         vm.$emit("alertFromChild", {
    //           variant: "success",
    //           msg: "BERHASIL INPUT DATA ABSEN KARYAWAN",
    //           showing: true,
    //         });
    //         vm.data.dataKaryawanId = "";
    //         vm.data.tglAbsenKaryawan = "";
    //         vm.data.jamMasuk = "";
    //         vm.data.jamKeluar = "";
    //         vm.$bvModal.hide("modal-input");
    //       } else {
    //         vm.button = "Simpan";
    //         vm.busy = false;

    //         vm.$emit("alertFromChild", {
    //           variant: "danger",
    //           msg: _.toUpper(res.data.message),
    //           showing: true,
    //         });
    //         vm.$bvModal.hide("modal-input");
    //       }
    //     })
    //     .catch((err) => {
    //       vm.button = "Simpan";
    //       vm.busy = false;
    //       vm.$emit("alertFromChild", {
    //         variant: "danger",
    //         msg: "TERJADI KESALAHAN PADA SERVER",
    //         showing: true,
    //       });
    //       vm.$bvModal.hide("modal-input");
    //     });
    // },
  },
};
</script>
